@import "@/assets/scss/style.scss";

.register-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f0f2f5;
    padding: 2rem;
    min-height: 100vh;

    &__outer-wrap {
        padding: 3rem;
    }

    &__inner-wrap {
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        margin-left: 1.55rem;
    }

    &__inner-wrap:nth-child(3) {
        margin: 0;
    }

    &__title {
        display: block;
        margin-bottom: 2rem;
        font-size: 2rem;
        color: $grey-9;

        &:nth-child(2) {
            margin-bottom: 0;
        }
    }

    &__form-title {
        font-size: 1.6rem;
    }

    &__symbol {
        font-size: 1.2rem;
        margin-left: 0.2rem;
    }

    .tax-title {
        margin-bottom: 0.5rem;
    }

    &__segment {
        margin: 3rem 0;
    }

    &__description {
        display: inline-block;
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }

    &__input {
        width: 10rem;
    }

    &__submit-button {
        align-self: center;
    }
}
