@import "@/assets/scss/style.scss";

.users-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // min-width: 1350px;

    &__wrap {
        overflow-x: auto;
        max-width: 100%;
    }

    &__table {
        min-width: 1350px;
    }

    &__edit-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 1rem;
        margin-top: 2rem;
    }

    &__add-button {
    }
}
