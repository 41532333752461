@import "@/assets/scss/style.scss";

.registration-attachment {
    &__footer {
        margin-top: 2rem;
    }

    &__mail {
        font-style: italic;
        color: $roltek-blue;
    }
}
