@import "@/assets/scss/style.scss";

.company-information {
    &__form-field {
        margin-bottom: 0;
        position: relative;

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            background-color: #1890ff; /* Pick your color */
            border-radius: 50%;
            position: absolute;
            top: 20px;
            left: -20px; /* Adjust as needed */
            transform: translateY(-50%);
        }

        & label {
            margin-right: 0.5rem;
        }

        & label::after {
            display: none;
        }
    }

    .taxable-person {
        display: flex;
        flex-direction: row;
    }
}

.flag-container {
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 30px;
    position: absolute;
    z-index: 100;
    top: -7px;
    left: 5px;
}

.ant-select-selection-selected-value {
    margin-left: 40px !important;
}
